// @flow

import * as React from "react";
import { Link as RouterLink } from "gatsby";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CMLogo from "../components/logo";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Head from '../components/head';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    textAlign: "center",
  },
  gridContainer: {
    height: "100%",
  },
  logoImage: {
    maxWidth: "365px",
    width: "100%",
  },
}));

export default (): React.Node => {
  const { gridContainer, logoImage, root } = useStyles();
  return (
    <>
      <Head />
      <Container className={root}>
        <Grid
          alignItems="center"
          className={gridContainer}
          container
          direction="column"
          justify="center">
          <Grid item>
            <Link component={RouterLink} to="/">
              <CMLogo className={logoImage} />
            </Link>
          </Grid>
          <Grid item>
            <Box mt={2}>
              <Typography component="h1">Sorry, page not found.</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box mt={4}>
              <Link component={RouterLink} to="/">
                Click here to validate your website branding.
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
